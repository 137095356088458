<template>
  <v-card>
      <v-card-title :class="`${color}--text`">
          {{ title | t }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
          <v-alert >
              {{ values ? 'edit_your_event' : 'set_your_event' | t }}

          </v-alert>
            <VFormBuilder ref="form" :fields="fields" :defaultsFieldsProps="defaultsFieldsProps" />

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-btn :color="color" rounded outlined @click="confirm" :loading="loading">
              <v-icon small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-btn v-if="showCancel"  outlined rounded @click="cancel" :disabled="loading">
              <v-icon small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import VFormBuilder from '@/components/VFormBuilder'
export default {
    components: {
        VFormBuilder
    },
    props: {
        name: String,
        title: String,
        text: String,
        values: Object,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'confirm'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-check'
        },
        showCancel: {
             type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean
    },
    computed: {
        defaultsFieldsProps () {
            return {
                rounded: true,
                outlined: true,
                dense: true
            }
        },
        fields () {
            const { $t: __, values } = this

            return [
                {
                name: 'type',
                input: () => import('vuetify/lib/components/VSelect'),
                attrs: vm => ({
                    value: values ? values.type : 'wedding',
                    startIcon () {
                    const { type: value } = vm.values
                    const { items } = vm.attrs.type
                
                    return items.find(t => t.value == value).icon
                    },
                    items: [
                    {
                        text: __('wedding'),
                        value: 'wedding',
                        icon: 'mdi-ring',
                    },
                    {
                        text: __('birthday'),
                        value: 'birthday',
                        icon: 'mdi-party-popper'
                    },
                    {
                        text: __('personal'),
                        value: 'personal',
                        icon: 'mdi-account'
                    },
                    {
                        text: __('business'),
                        value: 'business',
                        icon: 'mdi-briefcase-account-outline'
                    }
                    ],
                    label: __('event_type'),
                    required: true,
                    rules: this.getFieldRule('required'),
                }),
                
                },
                {
                name: 'date',
                input: () => import('@/components/VDateSelect.vue'),
                attrs: {
                    value: values ? values.date.toDate() : new Date(),
                    label: __('event_date'),
                    startIcon: 'mdi-calendar',
                    required: true,
                    rules: this.getFieldRule('required'),
                },
                col: {
                    sm: '6'
                }
                },
                {
                name: 'time',
                input: () => import('@/components/VTimeSelect.vue'),
                attrs: {
                    value: values ? values.time : moment(new Date()).locale('en').format('HH:mm'),

                    label: __('event_time'),
                    startIcon: 'mdi-clock',
                    required: true,
                    rules: this.getFieldRule('required')

                },
                
                col: {
                    sm: '6'
                }
                },
                {
                name: 'name',
                input: () => import('vuetify/lib/components/VTextField'),
                if: vm => vm.values['type'] != 'wedding',
                attrs: {
                    value: values ? values.name : '',
                    label: __('event_person'),
                    startIcon: 'mdi-account',
                    required: true,
                    rules: this.getFieldRule('required')

                },
                },
                {
                name: 'me',
                input: () => import('vuetify/lib/components/VTextField'),
                if: vm => vm.values['type'] == 'wedding',
                attrs: {
                    value: values ? values.me : '',
                    label: __('event_me'),
                    startIcon: 'mdi-account',
                    required: true,
                    rules: this.getFieldRule('required')

                },
                col: {
                    sm: '6'
                }
                },
                {
                name: 'partner',
                input: () => import('vuetify/lib/components/VTextField'),
                if: vm => vm.values['type'] == 'wedding',
                attrs: {
                    value: values ? values.partner : '',
                    label: __('event_partner'),
                    startIcon: 'mdi-account',
                    required: true,
                    rules: this.getFieldRule('required')

                },
                col: {
                    sm: '6'
                }
                },
                {
                name: 'location',
                input: () => import('vuetify/lib/components/VTextField'),
                attrs: {
                    value: values ? values.location : '',
                    label: __('event_location'),
                    startIcon: 'mdi-office-building-marker-outline',
                    required: true,
                    rules: this.getFieldRule('required')

                },
                },
                {
                    name: 'address',
                    input: () => import('@/components/VAddress'),
                    attrs: {
                        value: values ? values.address : '',
                        label: __('event_address'),
                        startIcon: 'mdi-map-marker',
                        required: true,
                        rules: this.getFieldRule('required')

                    },
                }

            ]
        }
    },
    methods: {
        confirm () {
            const { form } = this.$refs
            const { uid, displayName, photoURL } = this.currentUser
            form.$once('submit', data => {
                this.$emit('confirm', { user:{ uid, displayName, photoURL }, ...data })
            })
            form.submit()
        },
        cancel () {
            this.$emit('cancel')
        }
    }
}
</script>

<style>

</style>